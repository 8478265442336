body {
  font-family: 'Roboto';
  font-size: 14px;
  background-color: #ECEFF6;
}

.App {
  text-align: left;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.no-highlight:focus {
  outline:0 !important;
}

.slick-slide, .slick-slide *{ outline: none !important; }

.center {
  text-align: center;
}

.overflow {
  overflow-y: scroll;
}

.dialog-title {
  padding: 0px !important;
}

.m-menu-list {
  padding: 0px !important;
}

.m-menu-item {
  background-color: #FFFFFF !important;
  border-bottom: 2px solid #F1F1F1 !important;
}
.m-menu-item:last-child {
  border-bottom-left-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.m-menu-item:hover {
  background-color: #F1F1F1 !important;
}
.m-menu-item-selected {
  background-color: #29AAE1 !important;
  color: #fff !important;
}

.shadow {
  box-shadow: 2px 2px 2px rgba(	28, 37, 46, 0.3);
}

.items {
	display: flex;
	flex-wrap: wrap;
	margin-left: -10px;
  margin-top: -10px;
  margin-bottom: 40px;
}
.items .item {
  flex: 1;
  box-sizing: border-box;
  /* background: #e0ddd5; */
  color: #171e42;
  padding: 10px;
	margin-left: 10px;
  margin-top: 10px;
  min-width: 200px;
}
.items .item-home {
  flex: 1;
  box-sizing: border-box;
  background: #e0ddd5;
  color: #171e42;
	margin-left: 10px;
  margin-top: 10px;
  box-shadow: 2px 2px 2px rgba(	28, 37, 46, 0.3);
}
@media (min-width: 410px) {
  .items .item {
    max-width: calc(50% - 10px);
  }
}
@media (min-width: 620px) {
  .items .item {
    max-width: calc(33.33333% - 10px);
  }
}
@media (min-width: 830px) {
  .items .item {
    max-width: calc(25% - 10px);
  }
}
@media (min-width: 1040px) {
  .items .item {
    flex: 1 0 400px;
    max-width: calc(25% - 10px);
  }
  .items .item-home {
    flex: 1 0 400px;
    max-width: calc(33% - 10px);
  }
}

.nav {
  color: #fff;
  cursor: pointer;
  padding-bottom: 15px;
  border-bottom: 3px solid transparent;
  padding-top: 5px;
}

.nav:hover {
  border-bottom: 3px solid #2BAADF;
}

.nav-selected {
  color: #fff;
  cursor: pointer;
  padding-bottom: 5px;
  border-bottom: 3px solid #2BAADF;
  padding-top: 5px;
}
.organization-title{
  font-family: roboto, sans-serif;
  font-size: 18px;
  padding-bottom: 15px;
  border-bottom: 1px solid #C9D3E0;
  color: #607897;
}

*, :before, :after {
  box-sizing: border-box;
}

.heart {
  width: 50px;
  height: 50px;
  position: relative;
  transform: rotate(-45deg);
  border: 15px solid #2257a0;
  border-radius: 5px;
  border-top-color: transparent;
  border-right-color: transparent;
}
.heart:before, .heart:after {
  content: '';
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: absolute;
  border: 15px solid #2257a0;
  border-bottom-color: transparent;
  border-right-color: transparent;
}
.heart:before {
  top: -27px;
  left: -13px;
  transform: rotate(45deg);
}
.heart:after {
  top: -15px;
  left: 95px;
  transform: rotate(135deg);
}

.option-details {
  width: 600px;
}

@media (max-width: 830px) {
  .option-details {
    width: 300px;
  }
  .about-container {
    background-position: 30% !important;
  }
}

.message-content {
  background: #29ABE2 !important;
  color: #fff;
}

.link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.container {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

.profile-page {
  margin-top: 40px;
}

.profile-page .tabs {
  position: relative;
}

.profile-page .tabs:after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  border-right: 1px solid #C9D3E0;
}

.profile-page .tabs a {
  display: block;
  color: #627995;
  font-family: 'Roboto', serif;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 10px;
  border-radius: 5px;
  text-decoration: none;
  padding: 5px 10px;
}

.profile-page .title {
  color: #596675;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28px;
  margin-bottom: 30px;
}

.profile-page .sub-title {
  color: #6C7C8E;
  font-family: Roboto;
  font-size: 18px;
  line-height: 21px;
  margin-bottom: 20px;
}

.profile-page .ars-label {
  color: #6C7C8E;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 14px;
  margin-bottom: 5px;
  display: block;
}

.profile-page .ars-input {
  height: 40px;
  width: 100%;
  border: 1px solid #CCCCCC;
  border-radius: 2px;
  font-family: Roboto;
  background-color: #FFFFFF;
  color: #333333;
  font-size: 16px;
  padding: 0 10px;
}

.profile-page .ars-input:focus,
.profile-page .ars-input:active {
  border: 1px solid #6C7C8E;
  outline: none;
}

.profile-page .tabs a.selected {
  background-color: #FFECC4;
  color: #B67D00;
}

.profile-page > .MuiGrid-container > .MuiGrid-item {
  position: relative;
}

@media (min-width: 1024px) {
  .profile-page > .MuiGrid-container > .MuiGrid-item:first-child:after {
    content: "";
    position: absolute;
    right: 0;
    height: 100%;
    background: #C9D3E0;
    width: 1px;
    top: 20px;
  }
}

.footer {
  padding: 20px;
  background-color: #3D475A;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 99;
}

@media (max-width: 767px) {
  .tabs {
    display: flex;
  }
  .app-container {
    margin-bottom: 0 !important;
  }
  .footer {
    position: static;
  }
}
