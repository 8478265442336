body {
  height: 100%;
  margin: 0;
  padding: 0;
  /*font-family: 'Open Sans', sans-serif;*/
  background-size: cover;
  background-attachment: fixed;
  background-position: center;
  background-color: '#1c252e';
}
